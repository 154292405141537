<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <ic-cog :size="32" />
        </template>
        <template #default>Account Settings</template>
      </page-title>
    </page-section>
    <page-section>
      <me-form></me-form>
    </page-section>
  </page>
</template>

<script>
import IcCog from 'vue-material-design-icons/Cog'
import MeForm from '@/components/account/settings/MeForm'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'

export default {
  name: 'Profile',
  components: { PageSection, Page, IcCog, MeForm },
}
</script>
