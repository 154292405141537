<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="error"
        class="text-xs mb-10 p-4 rounded bg-red-200 text-red-600"
      >
        {{ error }}
      </div>
    </transition>

    <form
      @keydown.prevent.enter="save"
      class="my-4 text-sm"
    >
      <input-text
        label="Name"
        v-model.trim="name"
        input-id="me-name"
        input-name="me[name]"
        :disabled="loading"
        :error="$v.name.$invalid"
        :error-message="$v.name.$invalid ? 'Please enter your name' : null"
      />
      <input-text
        label="Email"
        v-model.trim="email"
        input-id="me-email"
        input-name="me[email]"
        disabled
      />
    </form>

    <the-button
      @click="save"
      :disabled="$v.$invalid || loading"
      primary
    >
      Save
    </the-button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import InputText from '@/components/input/InputText'
import Toast from '@/components/shared/Toast'

export default {
  name: 'me-form',
  components: { InputText, Toast },
  data() {
    return {
      loading: false,
      error: null,
      name: null,
      email: null,
    }
  },
  validations: {
    name: { required },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      userName: 'auth/getUserName',
    }),
  },
  methods: {
    ...mapActions({
      update: 'auth/update',
    }),
    async save() {
      if (this.$v.$invalid) return

      this.error = ''
      this.loading = true
      try {
        await this.update({
          name: this.name,
        })

        this.name = this.user.name

        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'User settings updated!',
            type: 'success',
          },
        })

        this.loading = false

        this.$emit('close')
      } catch (e) {
        this.error = e.response.data.error
        this.loading = false
      }
    },
  },
  mounted() {
    this.name = this.user.name || this.userName
    this.email = this.user.email
  },
}
</script>
